import { FC, lazy } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import DefaultLayout, { Loader } from "../components/layout";
import PrivateRoute from "../components/PrivateRoute";

const Home = lazy<FC>(() => import("./home"));
const Login = lazy<FC>(() => import("./login"));

const routes: RouteObject[] = [
  {
    element: <DefaultLayout />,
    loader: () => <Loader spinning />,
    children: [
      {
        element: <PrivateRoute><Home /></PrivateRoute>,
        path: "home",
        index: true,
      },
      {
        element: <Login />,
        path: "",
      },
      {
        element: <Outlet />,
        path: "*",
      },
    ],
  },
];

export default routes;
